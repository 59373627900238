import React from "react";
import logo from "../assets/images/android_phone_layout.png";
import playstore from "../assets/images/google-play-badge.png";

function Home() {
  return (
    <div className="flex flex-col h-full md:flex-row md:mt-20 md:mb-20 content-center">
      <img
        style={{ width: 300 }}
        src={logo}
        alt="Bikunity"
        className="self-center mt-20 md:mt-0"
      />
      <div className="text-2xl text-white md:ml-4 md:mr-4 self-center">
        <p className="text-2xl text-white md:ml-5 md:mr-5">
          Bikunity is a community application developed by bikers for bikers. If
          you have a flat you can ask for help from another member of the
          community, we help you find the nearest one. He can then join you and
          give you a hand fixing your flat.
        </p>
        <a href="">
          <img
            style={{ height: 120, width: 300 }}
            src={playstore}
            alt="playstore"
          />
        </a>
      </div>
    </div>
  );
}

export default Home;
