import { Routes, Route, Link } from "react-router-dom";

import ReactGA from "react-ga";

import Home from "./pages/Home";

import PrivacyPolicy from "./pages/PricacyPolicy";
import Contact from "./pages/Contact";

import icon from "./assets/images/icon.png";

import facebook from "./assets/images/Facebook_F_icon.svg.png";

import DirectionsBikeIcon from "@mui/icons-material/DirectionsBike";
import Success from "./pages/Success";

ReactGA.initialize("UA-179131268-1");
ReactGA.pageview(window.location.pathname + window.location.search);

function App() {
  return (
    <div className="bg-light-green h-full">
      <nav class="border-gray-200 px-2 sm:px-4 py-2.5 dark:bg-gray-800">
        <div class="container flex flex-wrap justify-between items-center mx-auto">
          <Link to="/" className="flex items-center md:mt-2">
            <DirectionsBikeIcon
              sx={{ color: "#ffffff", fontSize: 30, marginRight: 2 }}
            />
            <h3 className="text-3xl text-white">bikunity</h3>
          </Link>
          <button
            data-collapse-toggle="mobile-menu"
            type="button"
            class="inline-flex items-center p-2 ml-3 text-sm text-gray-500 rounded-lg md:hidden hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-gray-200 dark:text-gray-400 dark:hover:bg-gray-700 dark:focus:ring-gray-600"
            aria-controls="mobile-menu-2"
            aria-expanded="false"
          >
            <span class="sr-only">Open main menu</span>
            <svg
              class="w-6 h-6"
              fill="currentColor"
              viewBox="0 0 20 20"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fill-rule="evenodd"
                d="M3 5a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM3 10a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM3 15a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1z"
                clip-rule="evenodd"
              ></path>
            </svg>
            <svg
              class="hidden w-6 h-6"
              fill="currentColor"
              viewBox="0 0 20 20"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fill-rule="evenodd"
                d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                clip-rule="evenodd"
              ></path>
            </svg>
          </button>
          <div class="hidden w-full md:block md:w-auto" id="mobile-menu">
            <ul class="flex flex-col mt-4 md:flex-row md:space-x-8 md:mt-2 md:text-sm md:font-medium">
              <li>
                <Link
                  className="text-white md:text-lg block py-2 pr-4 pl-3 text-gray-700 border-b border-gray-100 hover:bg-gray-50 md:hover:bg-transparent md:border-0 md:hover:text-dark-green md:p-0 dark:text-gray-400 md:dark:hover:text-white dark:hover:bg-gray-700 dark:hover:text-white md:dark:hover:bg-transparent dark:border-gray-700"
                  to="/"
                >
                  Home
                </Link>
              </li>
              <li>
                <Link
                  className="text-white md:text-lg block py-2 pr-4 pl-3 text-gray-700 border-b border-gray-100 hover:bg-gray-50 md:hover:bg-transparent md:border-0 md:hover:text-dark-green md:p-0 dark:text-gray-400 md:dark:hover:text-white dark:hover:bg-gray-700 dark:hover:text-white md:dark:hover:bg-transparent dark:border-gray-700"
                  to="/privacy-policy"
                >
                  Privacy Policy
                </Link>
              </li>

              <li>
                <Link
                  className="text-white md:text-lg block py-2 pr-4 pl-3 text-gray-700 border-b border-gray-100 hover:bg-gray-50 md:hover:bg-transparent md:border-0 md:hover:text-dark-green md:p-0 dark:text-gray-400 md:dark:hover:text-white dark:hover:bg-gray-700 dark:hover:text-white md:dark:hover:bg-transparent dark:border-gray-700"
                  to="contact"
                >
                  Contact
                </Link>
              </li>
            </ul>
          </div>
        </div>
      </nav>
      <div className="ml-2 mr-2 md:ml-48 md:mr-48">
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/privacy-policy" element={<PrivacyPolicy />} />
          <Route path="/contact" element={<Contact />} />
          <Route path="/success" element={<Success />} />
        </Routes>
      </div>

      <footer class="p-4 ml-2 mr-2 md:ml-40 md:mr-40 rounded-lg md:flex md:items-center md:justify-between md:p-6 dark:bg-gray-800">
        <span class="text-sm text-gray-500 sm:text-center dark:text-gray-400 text-white">
          © 2022{" "}
          <a
            href="https://bikunity.com"
            class="hover:underline font-semibold"
            target="_blank"
            rel="noopener noreferrer"
          >
            bikunity™
          </a>
          . All Rights Reserved.
        </span>
        <ul class="flex flex-wrap items-center mt-3 sm:mt-0">
          <li>
            <Link
              className="text-white mr-4 text-sm text-gray-500 hover:underline md:mr-6 dark:text-gray-400"
              to="privacy-policy"
            >
              Privacy Policy
            </Link>
          </li>

          <li>
            <Link
              className="text-white mr-4 text-sm text-gray-500 hover:underline md:mr-6 dark:text-gray-400"
              to="contact"
            >
              Contact
            </Link>
          </li>
        </ul>

        <a
          href="https://www.facebook.com/bikunityapp"
          className="flex items-center text-white mt-3 md:mt-0 justify-end"
        >
          <img src={facebook} alt="facebook" style={{ width: 20 }} />
          <p className="ml-2">Follow us on Facebook</p>
        </a>
      </footer>
    </div>
  );
}

export default App;
