import React from "react";
import { Link } from "react-router-dom";

import ReactGA from "react-ga";
ReactGA.initialize("UA-179131268-1");
ReactGA.pageview(window.location.pathname + window.location.search);

function Success() {
  return (
    <div className="md:mt-20 md:mb-20">
      <h1 className="text-2xl text-white">
        Your message was succesfully submited !
      </h1>
      <Link to="/">Return home</Link>
    </div>
  );
}

export default Success;
